(function ($, window, document, undefined) {

  'use strict';

  $(function () {

    var is_iPad = navigator.userAgent.match(/iPad/i) != null;
    if(is_iPad){
        $('body').addClass('tablet');
    }
    
    var clickEventType = ((document.ontouchstart !== null) ? 'click' : 'touchstart');

    var $winWidth = $(window).width();
    $(window).resize(function(event) {
        var $winWidth = $(window).width();
    });

    var theme = 'app/themes/theme/';
    //GALLERY
    $('.slider,.hero__image').slick({
        dots: true,
        lazyLoad: 'progressive',
        infinite: true,
        prevArrow: '<div class="slick-prev slick-arrow"><svg role="img" class="prev"><use xlink:href="#icon-arrow-left"></use></svg></div>',
        nextArrow: '<div class="slick-next slick-arrow"><svg role="img" class="next"><use xlink:href="#icon-arrow-right"></use></svg></div>',
        adaptiveHeight: true
    });

    if($('.hero__image').length){
        $('.hero__image').on('lazyLoaded', function(event){
          $('.hero__image').addClass('slick-lazy-loaded')
        });
    }


    $('.tabs__slider').on('afterChange', function(e, slick, currentSlide, nextSlide) {
        var tab = $('.tabs__slider .slick-active').data('slick-index');
        $('.tabs__nav li').removeClass('active')
        $('.tabs__nav li[data-tab='+tab+']').addClass('active');
    });

    $('.tabs__nav li').on(clickEventType,function(e) {
        var tab =  $(this).data('tab');
        $('.tabs__slider').slick('slickGoTo',tab);
        $('.tabs__nav li').removeClass('active')
        $('.tabs__nav li[data-tab='+tab+']').addClass('active');
    });



    var s = skrollr.init({
        forceHeight: false
    });

    if (s.isMobile() || $winWidth < 769) {
        s.destroy();
    }


    //ACCRODIONS
    /*$('.acc-head').click(function(event) {
        var parentAcc,
            gallery,
            nextGallery;

        parentAcc = $(this).parent();
        if(parentAcc.hasClass('current')) return false;

        $(this).parents('.acc-wrap').find('.acc').removeClass('current');
        $(this).parents('.acc-wrap').find('.acc-content').stop().slideUp(300);
        
        

        $('.acc-content',parentAcc).stop().slideDown();
        $(parentAcc).addClass('current');


        //SHOW GALLERY IF AVAILABLE
        gallery = $(parentAcc).data('gallery');
        if (typeof gallery !== typeof undefined && gallery !== false) {
            $('.gallery.current').fadeOut(300,function(){
                $('.gallery.current').removeClass('current');
                nextGallery = $('.gallery[data-gallery="'+gallery+'"]');
                $('img',nextGallery).each(function(index, el) {
                   $(this).attr('src',$(this).attr('data-src')); 
                });
                $(nextGallery).fadeIn(300,function(){
                    $(nextGallery).addClass('current');
                    $(nextGallery).slick('setPosition');
                });
            });
        }
    });*/



    if($('body').is('.home, .desktop')){
        $(window).scroll(function(){
            var scrollPos = $(document).scrollTop();
            if(scrollPos >= 110){
                $('body').removeClass('home-anim');
            }else{
                $('body').addClass('home-anim');
            }
        });       
    }


    
    if($('body').is('.home, .tablet')){
        $(window).scroll(function(){
            var scrollPos = $(document).scrollTop();
            if(scrollPos >= $(window).height() - $('header.main').height() - 60){ 
                $('nav.main').addClass('fixed');
            }else{
                $('nav.main').removeClass('fixed');
            }
        });
    }


    // SCROLL-TARGETS
    $('.scroll-top').click(function (e) {
        e.preventDefault();
        $('html, body').stop().animate({ scrollTop: 0 },500);
    });


    $('.nav-toggle').on(clickEventType,function(e) {
        e.stopPropagation();
        e.preventDefault();
      
        $('body').toggleClass('mobile-nav');
    });


    var limit = 769;
    function refresh() {
       $winWidth = $(window).width();
       var w =  $winWidth<limit ? (location.reload(true)) :  ( $winWidth>limit ? (location.reload(true)) : $winWidth=limit );
    }

    var tOut;
    $(window).resize(function() {
        var resW = $(window).width();
        clearTimeout(tOut);
        if ( ($winWidth>limit && resW<limit) || ($winWidth<limit && resW>limit) ) {        
            tOut = setTimeout(refresh, 100);
        }
    });



    objectFitImages();

  




  });

})(jQuery, window, document);